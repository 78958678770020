// Global packages and components
import { useBranchOpen } from '~/hooks'

// Types
import type { OpeningTimes, BankHoliday } from '~/types'

interface OpenCardProps {
  openingTimes: OpeningTimes
  region: string
  bankHolidays?: BankHoliday[]
}

// Main export
const OpenCard = ({ openingTimes, region, bankHolidays }: OpenCardProps) => {
  const { timeNow, todaysTimes, tomorrowsTimes } = useBranchOpen({
    openingTimes,
    region,
    bankHolidays,
  })

  // No opening times exist
  if (!todaysTimes?.open || !todaysTimes?.close) return null

  // Display opening times
  return timeNow > todaysTimes.open && timeNow < todaysTimes.close ? (
    <div className="flex items-center gap-5">
      <div className="inline-block rounded-lg bg-vibrant-green px-4 py-2 font-semibold">
        Open
      </div>
      <p className="mb-0">Closes at {todaysTimes.close}</p>
    </div>
  ) : (
    <div className="flex items-center gap-5">
      <div className="inline-block rounded-lg bg-red-600 px-4 py-2 font-semibold text-white">
        Closed
      </div>
      {tomorrowsTimes?.open && tomorrowsTimes?.close && (
        <p className="mb-0">Opens at {tomorrowsTimes.open}</p>
      )}
    </div>
  )
}

export default OpenCard
