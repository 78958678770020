// Global packages and functions
import { useEffect, useState } from 'react'
import { formatDate, getBranchTimes } from '~/utils'

// Types
import type { OpeningTimes, OpeningTime, BankHoliday } from '~/types'

interface OpenCardProps {
  openingTimes: OpeningTimes
  region: string
  bankHolidays?: BankHoliday[]
}

// Main export
const useBranchOpen = ({
  openingTimes,
  region,
  bankHolidays,
}: OpenCardProps) => {
  // Local state
  const [todaysTimes, setTodaysTimes] = useState<OpeningTime | null>(null)
  const [tomorrowsTimes, setTomorrowsTimes] = useState<OpeningTime | null>(null)
  const [bankHols, setBankHols] = useState<BankHoliday[] | null>(
    bankHolidays ?? null
  )

  // Todays data
  const today = new Date()
  const todayFormatted = formatDate(today, 'input') // yyyy-mm-dd
  const dayNum = today.getDay() // 1, 2, 3
  const timeNow = today.toLocaleTimeString('en-GB', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  })

  // Tomorrows data
  const tomorrow = new Date(today.setDate(today.getDate() + 1))
  const tomorrowFormatted = formatDate(tomorrow, 'input') // yyyy-mm-dd

  // Fetch bank holidays from gov.uk and set our initial opening hours
  useEffect(() => {
    // Get branch opening hours
    const tod = getBranchTimes(openingTimes, dayNum - 1)
    setTodaysTimes(tod ?? null)

    const tom = getBranchTimes(openingTimes, dayNum)
    setTomorrowsTimes(tom ?? null)

    // Get bank holidays
    const getDates = async () => {
      return await fetch('https://www.gov.uk/bank-holidays.json')
        .then(response => response.json())
        .then(data => {
          if (region) {
            const dates = data[region]
            setBankHols(dates?.events ?? null)
          }
        })
    }

    if (!bankHolidays) {
      getDates()
    }
  }, [])

  // If bank holidays are fetched, check if we need to overwrite todays opening times
  useEffect(() => {
    if (bankHols) {
      if (bankHols.some(e => e.date == todayFormatted)) {
        setTodaysTimes(getBranchTimes(openingTimes, 7) ?? null)
      }

      if (bankHols.some(e => e.date == tomorrowFormatted)) {
        setTodaysTimes(getBranchTimes(openingTimes, 7) ?? null)
      }
    }
  }, [bankHols])

  return {
    timeNow,
    todaysTimes,
    tomorrowsTimes,
  }
}

export default useBranchOpen
